import { Col, Container, Form, Image, Nav, Navbar, Offcanvas, Row, Tab, Button } from "react-bootstrap";
import FileDropzone from "../components/FileDropzone";
import ProcessingLoader from "../components/Loader"
import DocType from "../components/DocType";
import { Link } from "react-router-dom";
import ReactJson from "react-json-view";
import VisualizedData from "../components/VisualizedData";
import { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function HomePage() {
  const [selectedDocType, setSelectedDocType] = useState("invoice");
  const [selectedDocTypeForKYC, setSelectedDocTypeForKYC] = useState("invoice");
  const [responseData, setResponseData] = useState({});
  const [JSONDownloadURL, setJSONDownloadURL] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const onChangeDocType = (type) => {
    setSelectedDocType(type);
    setResponseData({})
    setIsLoading(false)
  }

  const jsonViewTheme = "light";

  console.log({ selectedDocType });

  const exportToJson = (objectData) => {
    console.log({ objectData })
    let contentType = "application/json;charset=utf-8;";
    const jsonURL = "data:" +
      contentType +
      "," +
      encodeURIComponent(JSON.stringify(objectData));

    console.log({ jsonURL })

    setJSONDownloadURL(jsonURL)
    // let filename = "export.json";
    // if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    //   var blob = new Blob(
    //     [decodeURIComponent(encodeURI(JSON.stringify(objectData)))],
    //     { type: contentType }
    //   );
    //   navigator.msSaveOrOpenBlob(blob, filename);
    // } else {
    //   var a = document.createElement("a");
    //   a.download = filename;
    //   a.href =
    //     "data:" +
    //     contentType +
    //     "," +
    //     encodeURIComponent(JSON.stringify(objectData));
    //   a.target = "_blank";
    //   document.body.appendChild(a);
    //   a.click();
    //   document.body.removeChild(a);
    // }
  };
  const onHandleUploadFile = (file) => {
    setResponseData({})
    setIsLoading(true)
    try{
      console.log({ selectedDocTypeForKYC })
      const formData = new FormData();
      if (selectedDocTypeForKYC === "passport") {
        formData.append("image_file", file);
        axios.post("https://api.docextractor.com/mrz", formData).then((resp) => {
          console.log({ resp });
          exportToJson(resp.data)
          setResponseData(resp.data);
          setIsLoading(false);
          toast.success("Data extraction completed successfully!");
        });
      }
      if (
        selectedDocTypeForKYC === "aadhar" ||
        selectedDocTypeForKYC === "pan" ||
        selectedDocTypeForKYC === "vote"
      ) {
        formData.append("image_file", file);
        axios
          .post("https://imupl.click2tally.in/upload-image", formData)
          .then((resp) => {
            console.log({ resp });
            exportToJson(resp.data)
            setResponseData(resp.data);
            setIsLoading(false)
            toast.success("Data extraction completed successfully!");
          });
      }
      if (selectedDocType === "invoice") {
        formData.append("image_file", file);
        axios.post("https://idp.docextractor.com/ocr/", formData).then((resp) => {
          console.log({ resp });
          if (resp.data) {
            exportToJson(resp.data)
            setResponseData(resp.data);
            setIsLoading(false);
            toast.success("Data extraction completed successfully!");
          } else {
            setIsLoading(false)
            toast.error('No data.');
          }
        });
      }

    }catch(error){
      setIsLoading(false);
      toast.error('Something Went Wrong. Please try again after sometime.');
    }
  };

  const onHandleSelectDocType = (e) => {
    console.log({ event: e.target.value })
    setSelectedDocTypeForKYC(e.target.value)
    setResponseData({})
    setIsLoading(false)
  }

  return (
    <div className="Main-content">
      <Header />
      <ToastContainer />
      <Container>
        <DocType setDocType={onChangeDocType} docType={selectedDocType} />
        <div className="Main-box">
          {selectedDocType === "kyc" && (
            <Row>
              <Col lg={6}>
                <FileDropzone
                  setResponseData={setResponseData}
                  isPdf={false}
                  docType={selectedDocType}
                  onUploadFile={onHandleUploadFile}
                />
              </Col>
              <Col lg={6}>
                <div className="pink-box pink-box2">
                  <p>File must be of only .jpg, .png type.</p>
                  <Form.Select aria-label="Default select example" onChange={onHandleSelectDocType}>
                    <option>Select Document Type</option>
                    <option value="passport">Passport</option>
                    <option value="aadhar">Aadhar</option>
                    <option value="pan">Pan</option>
                    <option value="voterId">Voter Id</option>
                  </Form.Select>
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="custom-pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Visualized Data</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Standardized JSON</Nav.Link>
                    </Nav.Item>
                    <Link style={{ pointerEvents: JSONDownloadURL ? '' : 'none' }} to={JSONDownloadURL} className="btn-border-pink" download="export.json">
                      Download as JSON File
                    </Link>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                    {isLoading && (
                        <div className="text-center">
                          <ProcessingLoader message="We are processing your file data. It will take few moments." />
                        </div>
                      )}
                      <VisualizedData responseData={responseData} />
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                    {isLoading && (
                        <div className="text-center">
                          <ProcessingLoader message="We are processing your file data. It will take few moments." />
                        </div>
                      )}
                      <ReactJson theme={jsonViewTheme} src={responseData} />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          )}
          {selectedDocType === "invoice" && (
            <Row>
              <Col lg={6}>
                <FileDropzone
                  setResponseData={setResponseData}
                  isPdf={true}
                  docType={selectedDocType}
                  onUploadFile={onHandleUploadFile}
                />
              </Col>
              <Col lg={6}>
                <div className="pink-box">
                  <p>File must be of only .pdf, .jpg, .png type.</p>
                </div>
                <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                  <Nav variant="pills" className="custom-pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Visualized Data</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Standardized JSON</Nav.Link>
                    </Nav.Item>
                    <Link style={{ pointerEvents: JSONDownloadURL ? '' : 'none' }} to={JSONDownloadURL} className="btn-border-pink" download="export.json">
                      Download as JSON File
                    </Link>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first" className="imageview">
                    {isLoading ? (
                        <div className="text-center" >
                          <ProcessingLoader message="We are preparing your data. It may take upto a few minutes." />
                        </div>
                      ):(<VisualizedData responseData={responseData} />)}
                      
                    </Tab.Pane>
                    <Tab.Pane eventKey="second" className="imageview">
                    {isLoading ? (
                        <div className="text-center">
                          <ProcessingLoader message="We are preparing your data. It may take upto a few minutes." />
                        </div>
                      ):(<ReactJson theme={jsonViewTheme} src={responseData} />)}
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </Col>
            </Row>
          )}
        </div>
      </Container>
      <Footer />
    </div>
  );
}

export default HomePage;


const Footer = () => {
  return (
    <footer
      style={{
        textAlign: 'center',
        padding: '40px 20px',
        backgroundColor: '#fff',
        borderTop: '1px solid #e5e7eb',
      }}
    >
      {/* Logo Section */}
      <div style={{ marginBottom: '20px' }}>
        <a href="https://docextractor.com/" rel="home">
          <img
            src="https://docextractor.com/wp-content/uploads/2024/10/9a4a66f0-7d51-491c-b555-a01748055c32-removebg-preview-1-e1729683543608.png"
            alt="DocExtractor"
            width="150"
            height="70"
          />
        </a>
      </div>

      {/* Navigation Links */}
      <nav>
        <ul
          style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '30px',
            listStyleType: 'none',
            padding: 0,
            marginBottom: '20px',
            fontSize: '16px',
            fontWeight: '500',
            color: '#374151',
          }}
        >
          <li>
            <a href="https://docextractor.com/"
                          style={{ color: '#4b5563', textDecoration: 'none' }}
            >Home</a>
          </li>
          <li>
            <a href="https://docextractor.com/#Product"
                          style={{ color: '#4b5563', textDecoration: 'none' }}
>Product</a>
          </li>
          <li>
            <a href="https://docextractor.com/blog/"
                          style={{ color: '#4b5563', textDecoration: 'none' }}
>Blog</a>
          </li>
          <li>
            <a href="https://docextractor.com/pricing/"              style={{ color: '#4b5563', textDecoration: 'none' }}
            >Pricing</a>
          </li>
          <li>
            <a href="https://docextractor.com/contact-us/"              style={{ color: '#4b5563', textDecoration: 'none' }}
            >Contact us</a>
          </li>
        </ul>
      </nav>

      {/* Social Media Links */}
      <div style={{ marginBottom: '20px' }}>
        <a
          href="https://www.youtube.com/@docextractor4906"
          target="_blank"
          rel="noopener noreferrer"
          style={{ margin: '0 10px', color: '#94a3b8' }}
        >
          <i className="fab fa-youtube"></i>
        </a>
        <a
          href="https://www.linkedin.com/company/docextractor/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ margin: '0 10px', color: '#94a3b8' }}
        >
          <i className="fab fa-linkedin"></i>
        </a>
      </div>

      {/* Privacy Policy & Terms */}
      <p style={{ marginBottom: '20px' }}>
        <a href="https://docextractor.com/privacy-policy-2/" style={{ marginRight: '10px' }}               style={{ color: '#4b5563', textDecoration: 'none' }}
        >
          Privacy Policy
        </a>
        {' || '}
        <a href="https://docextractor.com/terms-and-conditions/" style={{ marginLeft: '10px' }}               style={{ color: '#4b5563', textDecoration: 'none' }}
        >
          Terms And Conditions
        </a>
      </p>

      {/* Copyright Text */}
      <p style={{ fontSize: '14px', color: '#6b7280' }}>
        © Copyright 2024 DocExtractor · All rights reserved.
      </p>
    </footer>
  );
};

const Header = () => {
  return (
    <header
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '20px 40px',
        backgroundColor: '#fff',
        borderBottom: '1px solid #e5e7eb',
      }}
    >
      {/* Logo Section */}
      <div>
        <a href="https://docextractor.com/" rel="home">
          <img
            src="https://docextractor.com/wp-content/uploads/2024/10/9a4a66f0-7d51-491c-b555-a01748055c32-removebg-preview-1-e1729683543608.png"
            alt="DocExtractor"
            width="160"
            height="60"
          />
        </a>
      </div>

      {/* Navigation Links */}
      <nav style={{ marginLeft: 'auto' }}>
        <ul
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '30px',
            listStyleType: 'none',
            padding: 0,
            margin: 0,
            fontSize: '16px',
            fontWeight: '500',
          }}
        >
          <li>
            <a
              href="https://docextractor.com/"
              style={{ color: '#4b5563', textDecoration: 'none' }}
            >
              Home
            </a>
          </li>
          <li>
            <a
              href="https://docextractor.com/#Product"
              style={{ color: '#4b5563', textDecoration: 'none' }}
            >
              Product
            </a>
          </li>
          <li>
            <a
              href="https://docextractor.com/blog/"
              style={{ color: '#4b5563', textDecoration: 'none' }}
            >
              Blog
            </a>
          </li>
          <li>
            <a
              href="https://docextractor.com/pricing/"
              style={{ color: '#4b5563', textDecoration: 'none' }}
            >
              Pricing
            </a>
          </li>
          <li>
            <a
              href="https://docextractor.com/contact-us/"
              style={{ color: '#4b5563', textDecoration: 'none' }}
            >
              Contact us
            </a>
          </li>
          {/* Search Icon */}
          <li>
            <a href="#" style={{ color: '#4b5563', textDecoration: 'none' }}>
              <i className="fas fa-search" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
      </nav>

      {/* CTA Button */}
      <a
        href="https://docextractor.com/demo/"
        style={{
          backgroundColor: '#1e3a8a',
          color: '#fff',
          padding: '10px 20px',
          borderRadius: '8px',
          textDecoration: 'none',
          fontWeight: 'bold',
        }}
      >
        LIVE DEMO
      </a>
    </header>
  );
};