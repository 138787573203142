import React from "react";
import { Spinner } from "react-bootstrap";

const ProcessingLoader = ({ message }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "50vh",
        // backgroundColor: "#f9f9f9",
      }}
    >
      <Spinner
        animation="border"
        role="status"
        style={{ width: "4rem", height: "4rem", marginBottom: "20px", color: "#1e3a8a" }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <p
        style={{
          fontSize: "18px",
          color: "#333",
          textAlign: "center",
          margin: 0,
        }}
      >
        {message || "Processing your request. Please wait..."}
      </p>
    </div>
  );
};

export default ProcessingLoader;
