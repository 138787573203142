import { Col, Form, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useState } from "react";
import Select from "react-select";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./CustomScrollbar.css";

function VisualizedData({ responseData }) {
  const [data, setData] = useState(responseData?.line_items || []);
  const [selectedColumns, setSelectedColumns] = useState(["item_name", "item_description"]);
  const [isAllColumnsSelected, setIsAllColumnsSelected] = useState(false);

  const allColumns = [
    { value: "item_name", label: "Item Name" },
    { value: "additional_item_cost", label: "Additional Item Cost" },
    { value: "item_cess_rate_percentage", label: "CESS Rate(%)" },
    { value: "item_cgst_rate_percentage", label: "CGST(%)" },
    { value: "item_cgst_amount", label: "CGST" },
    { value: "item_description", label: "Description" },
    { value: "item_discount_percentage", label: "Discount(%)" },
    { value: "item_discount_amount", label: "Discount" },
    { value: "item_igst_rate_percentage", label: "IGST(%)" },
    { value: "item_igst_amount", label: "IGST" },
    { value: "item_quantity", label: "Quantity" },
    { value: "item_quantity_cess_rate_percentage", label: "Quantity CESS(%)" },
    { value: "item_quantity_cess_amount", label: "Quantity CESS" },
    { value: "item_rate", label: "Rate" },
    { value: "item_serial_number", label: "Serial Number" },
    { value: "item_sgst_rate_percentage", label: "SGST(%)" },
    { value: "item_sgst_amount", label: "SGST" },
    { value: "item_total_amount", label: "Total Amount" },
    { value: "item_unit_of_measurement", label: "Unit of Measurement" },
  ];

  const handleCellEdit = (rowIndex, columnId, value) => {
    const updatedData = [...data];
    updatedData[rowIndex][columnId] = value;
    setData(updatedData);
  };

  const handleColumnChange = (selectedOptions) => {
    if (selectedOptions.some((option) => option.value === "all_columns")) {
      setIsAllColumnsSelected(true);
      setSelectedColumns(allColumns.map((col) => col.value));
    } else {
      setIsAllColumnsSelected(false);
      setSelectedColumns(selectedOptions.map((option) => option.value));
    }
  };

  const dropdownOptions = [
    { value: "all_columns", label: "All Columns" },
    ...allColumns,
  ];

  const textStyle = {
    border: "none",
    backgroundColor: "transparent",
    outline: "none",
    padding: "0",
    fontSize: "14px",
  };

  const columns = allColumns
    .filter((column) => selectedColumns.includes(column.value))
    .map((column) => ({
      name: <span title={column.label}>{column.label}</span>,
      selector: (row) => row[column.value],
      editable: true,
      cell: (row, index) => (
        <input
          type="text"
          style={textStyle}
          value={row[column.value] || ""}
          onChange={(e) => handleCellEdit(index, column.value, e.target.value)}
        />
      ),
    }));

  return (
    <Form>
      <Row>
        {Object.entries(responseData).map(
          ([key, value]) =>
            key !== "line_items" && (
              <Form.Group as={Col} className="mb-2" md="4" controlId="validationCustom01">
                <Form.Label>{key}</Form.Label>
                <Form.Control required type="text" defaultValue={value} />
              </Form.Group>
            )
        )}
      </Row>

      {/* Dropdown to select columns */}
      {data?.length > 0 && (
      <Row className="mb-3">
        <Col md="12">
          <Form.Label>Select Columns to Display</Form.Label>
          <Select
            options={dropdownOptions}
            isMulti
            value={
              isAllColumnsSelected
                ? [{ value: "all_columns", label: "All Columns" }]
                : dropdownOptions.filter((option) =>
                    selectedColumns.includes(option.value)
                  )
            }
            onChange={handleColumnChange}
            // isDisabled={isAllColumnsSelected} // Disable when "All Columns" is selected
          />
        </Col>
      </Row>
      )}
      {data?.length > 0 && (
        <div className="data-table-container">
          <DataTable
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            className="lato-regular"
            pointerOnHover
            customStyles={{
              headCells: {
                style: {
                  color: "black",
                  backgroundColor: "white",
                  borderBottom: "2px solid #e2e8f0",
                },
              },
              cells: {
                style: {
                  color: "#2d3748",
                  fontSize: "14px",
                  padding: "10px 12px",
                },
              },
              rows: {
                style: {
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "#f7fafc",
                  },
                },
              },
            }}
          />
        </div>
      )}
    </Form>
  );
}

export default VisualizedData;
